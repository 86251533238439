import React from "react";
import { Location } from "@reach/router";
import styled, { css } from "styled-components";
import { ACSS } from "@upsolve/ui";
import pathToBreadcrumb from "../SEO/pathToBreadcrumb";

export const BreadCrumbCSS = css`
  margin: 0 0 0.25em;
  ol {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    li {
      list-style-type: none;
      margin-right: 4px;
      a,
      a:hover {
        ${ACSS}
      }
    }
    li:not(:first-child):before {
      content: "/";
      margin-right: 4px;
      opacity: 0.35;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
    margin: 0 1.25em 0.25em;
  }
`;

const BreadCrumbWrap = styled.nav`
  ${BreadCrumbCSS}
`;

interface IBreadcrumbNavProps {
  breadcrumbs?: any[];
}

const BreadcrumbNav: React.FC<IBreadcrumbNavProps> = ({ breadcrumbs }) => (
  <Location>
    {({ location }) => {
      return (
        <BreadCrumbWrap data-test-id="breadcrumb" aria-label="Breadcrumb">
          <ol>
            {Array.prototype.map.call(breadcrumbs || pathToBreadcrumb(location.pathname), (crumb) => (
              <li key={crumb.slug}>
                <a href={`${crumb.slug}`}>{crumb.title}</a>
              </li>
            ))}
          </ol>
        </BreadCrumbWrap>
      );
    }}
  </Location>
);

export default BreadcrumbNav;
